"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
require("@/node_modules/ngtouchstart/src/ngTouchstart.js");
require("./libs/canvas-event.min.js");
//@ts-ignore
require("./libs/canvas-puzzle.min.js");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
//@ts-ignore
const jigsaw = window.jigsaw;
function toFixedNumber(num, digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
}
class Option {
    // private _puzzleUri: string
    constructor(initial) {
        this.numberOfPieces = [10, 20, 30, 40, 50, 60, 70, 80, 100, 150, 200];
        this.defaultPieces = 40;
        this.zoom = 0;
        if (initial != null) {
            const data = JSON.parse(initial);
            if (data.numberOfPieces) {
                this.numberOfPieces = data.numberOfPieces;
                if (this.numberOfPieces.indexOf(this.defaultPieces) == -1) {
                    this.defaultPieces = this.numberOfPieces[0];
                }
            }
            if (data.defaultPieces && (data.numberOfPieces.indexOf(data.defaultPieces) != -1)) {
                this.defaultPieces = data.defaultPieces;
            }
            if (data.zoom != undefined) {
                this.zoom = data.zoom;
            }
            if (data.storage != undefined) {
                this.storage = data.storage;
            }
        }
    }
    setStorage(_shapes, ctrl) {
        const shapes = [
            ..._shapes.map((item) => {
                if (item.type == 'piece') {
                    return [{
                            originalTX: item.originalTX,
                            originalTY: item.originalTY,
                            tx: item.tx,
                            ty: item.ty,
                            rotation: item.rotation,
                        }];
                }
                else {
                    return item.pieces.map((itemInGroup) => {
                        return {
                            originalTX: itemInGroup.originalTX,
                            originalTY: itemInGroup.originalTY,
                            tx: itemInGroup.tx,
                            ty: itemInGroup.ty,
                            rotation: itemInGroup.rotation,
                        };
                    });
                }
            }).flat()
        ];
        if ((ctrl.puzzleUri == ctrl.jsaw.opts.defaultImage) && (ctrl.puzzleId != null)) {
            if (shapes.length > 0) {
                this.storage = {
                    puzzleId: ctrl.puzzleId,
                    puzzleUri: ctrl.puzzleUri,
                    dataPuzzleUri: undefined,
                    zoom: ctrl.zoom,
                    startWith: ctrl.startWith,
                    shapes: shapes,
                };
            }
            else {
                this.storage = undefined;
            }
        }
    }
    setStartWith(value) {
        if (this.storage != undefined)
            this.storage.startWith = value;
    }
}
// const defaultOption: IOption = {
//     numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
//     defaultPieces: 20,
//     storage: undefined
// }
class PuzzlesCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.imageLoaded = false;
        this.option = new Option(localStorage.getItem(`${this.constructor.name}_option`));
        this.timeRemaining = '00:00';
        this.timerWork = false;
        this.flup = true;
        this.imageLoaded$ = new rxjs_1.BehaviorSubject(false);
        this.jigsawLoaded$ = new rxjs_1.BehaviorSubject(false);
        this.piecesSizeCache = new Map();
        this.deviceRatio = window.devicePixelRatio || 1;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = 0;
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.puzzleState = 'show_all';
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = PuzzlesCtrl.formatTime(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this.makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
        this.imageLoaded$.pipe((0, operators_1.tap)((value) => {
            if (value) {
                this.$scope.$apply(() => {
                    this.imageLoaded = true;
                });
            }
        })).subscribe();
        this.ConfigService.settings$.pipe(
        // skip(1),
        (0, operators_1.pluck)('show_ghost'), (0, operators_1.concatMap)((show_ghost) => {
            return this.imageLoaded$.pipe((0, operators_1.filter)(value => (value)), (0, operators_1.mapTo)(show_ghost));
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.tap)((show_ghost) => {
            const canvas = document.getElementById('image-ghost');
            this.previewMarginLeft = -(canvas.width / 2 / this.deviceRatio) + "px";
            canvas.style.marginLeft = this.previewMarginLeft;
        })).subscribe();
        // this.jigsawLoaded$.pipe(
        //     tap((state) => {
        //         if (state) {
        //             if (!this.ConfigService.cookieSettings.show_preview)
        //                 this.onShuffle()
        //         }
        //     })
        // ).subscribe()
        // this.ConfigService.settings$.pipe(
        //     skip(1),
        //     pluck('show_preview'),
        //     distinctUntilChanged(),
        //     tap((show_preview) => {
        //         this.jsaw.opts.shuffled = !show_preview
        //         console.log(
        //             this.jsaw.opts,
        //         )
        //     })
        // ).subscribe()
    }
    $onInit() {
        this.$scope.$watch('$ctrl.option', (option, _option) => {
            // console.log(option)
            if (_option)
                localStorage.setItem(`${this.constructor.name}_option`, JSON.stringify(option));
        }, true);
    }
    newGame(preStart) {
        if (this.startWith == 0) {
            if (preStart != undefined)
                preStart();
            this.jsaw.opts.piecesStored = [];
            this.jsaw.render();
            this._startTimer.next(null);
            this.option.setStorage([], this);
        }
        else {
            this._confirmNewGame(() => {
                if (preStart != undefined)
                    preStart();
                this.jsaw.opts.piecesStored = [];
                this.jsaw.render();
                this.startWith = 0;
                this._startTimer.next(null);
            });
        }
    }
    $postLink() {
        const linkEvents = [
            document.getElementById('logo'),
            document.getElementById('puzzle-custom'),
            document.getElementById('puzzle-random'),
        ].filter((el) => el).map((el) => {
            return (0, rxjs_1.fromEvent)(el, 'click');
        });
        (0, rxjs_1.merge)(...linkEvents).pipe((0, operators_1.filter)(event => event.cancelable), (0, operators_1.tap)(event => {
            event.preventDefault();
        })).pipe((0, operators_1.tap)((event) => {
            var _a;
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (this.startWith == 0) {
                (_a = event.target) === null || _a === void 0 ? void 0 : _a.dispatchEvent(e);
                // window.location.href = `${event.currentTarget.href}?size=${this.size}`
            }
            else {
                this._confirmNewGame(() => {
                    var _a;
                    (_a = event.target) === null || _a === void 0 ? void 0 : _a.dispatchEvent(e);
                });
            }
        })).subscribe();
        if (this.puzzleId == null)
            this.puzzleMakerGame();
        else
            this.$timeout(() => {
                this._initGame();
            });
    }
    showPuzzle(puzzleState) {
        switch (puzzleState) {
            case "edge":
                if (this.puzzleState == 'edge') {
                    this.jsaw.ce.find("#middle").attr("hide", false);
                    this.jsaw.ce.find("#edge").attr("hide", false);
                    this.puzzleState = 'show_all';
                }
                else {
                    this.jsaw.ce.find("#middle").attr("hide", true);
                    this.jsaw.ce.find("#edge").attr("hide", false);
                    this.puzzleState = puzzleState;
                }
                this.jsaw.ce.redraw();
                break;
            case "middle":
                if (this.puzzleState == 'middle') {
                    this.jsaw.ce.find("#middle").attr("hide", false);
                    this.jsaw.ce.find("#edge").attr("hide", false);
                    this.puzzleState = 'show_all';
                }
                else {
                    this.jsaw.ce.find("#middle").attr("hide", false);
                    this.jsaw.ce.find("#edge").attr("hide", true);
                    this.puzzleState = puzzleState;
                }
                this.jsaw.ce.redraw();
                break;
            // case "show_all":
            //     this.jsaw.ce.find("*").attr("hide", false);
            //     this.jsaw.ce.redraw();
            //     break
        }
    }
    onShuffle() {
        this.jsaw.shuffle();
    }
    calcPieces(pieces) {
        if (this.imageLoaded) {
            if (this.piecesSizeCache.has(pieces)) {
                return this.piecesSizeCache.get(pieces);
            }
            else {
                const width = this.jsaw.img.width;
                const height = this.jsaw.img.height;
                let parts = undefined;
                for (var i = 0; i < this.option.numberOfPieces.length; i += 1) {
                    if (pieces == this.option.numberOfPieces[i]) {
                        let size = ~~Math.sqrt(width * height / pieces);
                        let cols = ~~(width / size);
                        let rows = ~~(height / size);
                        while (cols * rows < pieces) {
                            size--;
                            cols = ~~(width / size);
                            rows = ~~(height / size);
                        }
                        if (parts != cols * rows) {
                            parts = cols * rows;
                            this.piecesSizeCache.set(pieces, parts);
                            return parts;
                        }
                    }
                }
            }
        }
    }
    changeSize(size) {
        this.newGame(() => {
            this.option.defaultPieces = size;
            this.jsaw.set_parts(size);
        });
    }
    onZoom(direct) {
        if (direct) {
            this.jsaw.eventBus.emit(jigsaw.Events.JIGSAW_ZOOMIN);
        }
        else {
            this.jsaw.eventBus.emit(jigsaw.Events.JIGSAW_ZOOMOUT);
        }
        this.option.zoom = toFixedNumber(this.jsaw.ce.__zoom - 1, 1);
    }
    startPan(direct) {
        switch (direct) {
            case 0:
                this.jsaw.ce.goLeft = true;
                break;
            case 1:
                this.jsaw.ce.goUp = true;
                break;
            case 2:
                this.jsaw.ce.goRight = true;
                break;
            case 3:
                this.jsaw.ce.goDown = true;
                break;
        }
    }
    stopPan(direct) {
        switch (direct) {
            case 0:
                this.jsaw.ce.goLeft = false;
                break;
            case 1:
                this.jsaw.ce.goUp = false;
                break;
            case 2:
                this.jsaw.ce.goRight = false;
                break;
            case 3:
                this.jsaw.ce.goDown = false;
                break;
        }
    }
    puzzleMakerGame(closeable = false) {
        if (this.startWith == 0) {
            this._puzzleMakerGame(closeable);
        }
        else {
            this._confirmNewGame(() => {
                this._puzzleMakerGame(closeable);
            });
        }
    }
    pauseGame() {
        if (this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    _initGame() {
        var _a;
        const self = this;
        let zoom = 0;
        let piecesStored = [];
        if (this.puzzleUri == ((_a = this.option.storage) === null || _a === void 0 ? void 0 : _a.puzzleUri)) {
            piecesStored = this.option.storage.shapes;
            this.startWith = this.option.storage.startWith;
            this.timeRemaining = PuzzlesCtrl.formatTime(this.option.storage.startWith);
            zoom = this.option.zoom;
        }
        this.jsaw = new jigsaw.Jigsaw({
            offsetTop: 16,
            shuffled: true,
            defaultImage: this.puzzleUri,
            spread: .8,
            defaultPieces: this.option.defaultPieces,
            numberOfPieces: this.option.numberOfPieces,
            piecesStored: piecesStored,
            piecesNumberTmpl: "%d pieces",
            squarePieces: false,
            rotatePieces: false,
        });
        if (zoom != 0)
            this.jsaw.zoomBy(zoom);
        this.jsaw.tmp_img.onload = function () {
            self.ModalServiceFactory.close('__all__');
            self.jsaw.draw_image(this);
            self.jsaw.render();
            self.imageLoaded$.next(true);
        };
        this.jsaw.tmp_img.onerror = function () {
            self.imageLoaded$.next(false);
        };
        this.jsaw.eventBus.on(jigsaw.Events.RENDER_FINISH, function () {
            self.jigsawLoaded$.next(true);
            self._startTimer.next(null);
        });
        this.jsaw.eventBus.on(jigsaw.Events.JIGSAW_FOCUS_PIECE, function () {
            self._startTimer.next(true);
        });
        this.jsaw.eventBus.on(jigsaw.Events.RENDER_REQUEST, function () {
            self.jigsawLoaded$.next(false);
        });
        this.jsaw.eventBus.on(jigsaw.Events.PIECES_CONNECTED, function () {
            self.SoundService.play('connected');
        });
        this.jsaw.eventBus.on(jigsaw.Events.PIECES_DRAGEND, function (shapes) {
            self.option.setStorage(shapes, self);
        });
        this.jsaw.eventBus.on(jigsaw.Events.JIGSAW_COMPLETE, function () {
            self.endGame();
        });
    }
    _puzzleMakerGame(closeable = false) {
        this.flup = false;
        this.startWith = 0;
        this._startTimer.next(null);
        this.$timeout(() => {
            this.flup = true;
        }, 200).then(() => {
            this.ModalServiceFactory.open({
                id: 'puzzle-custom',
                forced: true,
                component: "alert-comp",
                scope: this.$scope,
                template: require('./puzzle-custom.ng.html'),
                strategy: "override",
                afterCompiled: this._initGame.bind(this),
                extraContext: {
                    keyCodeEsc: this.puzzleId ? ['Escape'] : []
                }
            });
        });
    }
    endGame() {
        this.SoundService.play('win');
        this._stopTimer.next(true);
        this.ModalServiceFactory.open({
            id: 'game_status',
            templateUrl: 'end_game.html',
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                show_timer: this.ConfigService.cookieSettings.show_timer,
                timeRemaining: this.timeRemaining,
            }
        });
    }
    makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, _startWith]) => {
            const startWith = _startWith + i;
            this.option.setStartWith(startWith);
            return startWith;
        }), 
        // switchMap(([i, startWith]) => {
        //     return from(
        //         this.gamedb.upsert(`game`, (oldDoc => {
        //             if (oldDoc.grid) {
        //                 oldDoc['startWith'] = startWith + i;
        //                 return oldDoc
        //             }
        //             return null
        //         }))
        //     ).pipe(
        //         mapTo(startWith + i),
        //     )
        // }),
        (0, operators_1.map)((sec) => {
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                this.timeRemaining = PuzzlesCtrl.formatTime(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer.pipe((0, operators_1.tap)((value) => {
            if (value) {
                this.startWith = 0;
                this.option.setStorage([], this);
            }
        }))), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    _confirmNewGame(callback, cancel_callback) {
        const timerWord = this.timerWork;
        this._stopTimer.next(null);
        return this.ModalServiceFactory.open({
            id: 'puzzles_new_game',
            component: "confirm-comp",
            scope: this.$scope,
            extraContext: {
                settings: {}
            }
        }).then((result) => {
            if (result) {
                this.jsaw.opts.piecesStored = [];
                this.option.setStorage([], this);
                if (callback != undefined)
                    callback();
            }
            else {
                if (timerWord) {
                    this._startTimer.next(null);
                    this._startTimer.next(true);
                }
                if (cancel_callback != undefined)
                    cancel_callback();
            }
        });
    }
    static formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
PuzzlesCtrl.$inject = [
    '$scope',
    '$timeout',
    'ModalServiceFactory',
    'ConfigService',
    'SoundService',
];
const appModule = ng.module('app');
appModule.requires.push('ngTouchstart');
appModule.controller('indexCtrl', ['$scope', ($scope) => {
        $scope.$ctrl = this;
        const option = new Option(localStorage.getItem(`${PuzzlesCtrl.name}_option`));
        if (option.storage != undefined) {
            $scope.storage = option.storage;
        }
        return;
    }]);
appModule.component('gamePuzzles', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PuzzlesCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<",
        puzzleUri: "<",
        puzzleId: "<",
        // puzzleMaker: "<",
    }
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider',
    (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('puzzles/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'connected': require('./sounds/click.ogg').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            sound_effects: true,
            show_timer: true,
        });
    }]);
